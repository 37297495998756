/**
 * Created by spepa on 06.02.2023
 */

var KlondikeScene = GameSceneBase.extend({
    ctor: function () {
        this._super({ level: new Level(0, 0) });
    },

    onSceneLoaded: function () {
        this._super();

        this.createButtons();

        this.statusBar = new KlondikeStatusView(this.game);
        this.addChild(this.statusBar);

        this.cardTableView = new CardTableView(this.game.columnsByType(CardColumnTypes.TABLE));
        this.cardTableView._repositionCards();
        this.addChild(this.cardTableView);

        var openCardsView = new OpenCardsView(this.game.columnsByType(CardColumnTypes.OPEN));
        this.addChild(openCardsView);

        this.handBlockView = new HandBlockView(this.game.hand, this.game.columnsByType(CardColumnTypes.HAND));
        this.addChild(this.handBlockView);
    },

    createButtons: function () {
        var styles = cleverapps.styles.KlondikeScene;

        var node = this.buttons = new cc.Node();
        node.setContentSize(this.width, styles.autocomplete.height);
        this.addChild(node);

        var undoBlock = new UndoBlock(this.game.log);
        node.addChild(undoBlock);
        this.undoBlock = new HidingNode(undoBlock, cleverapps.UI.VERTICAL);
        cleverapps.focusManager.registerControl("undoBlock", this.undoBlock);

        var hintBlock = new HintBlock(this.game);
        node.addChild(hintBlock);
        this.hintBlock = new HidingNode(hintBlock, cleverapps.UI.VERTICAL);
        cleverapps.focusManager.registerControl("hintBlock", this.hintBlock);

        var autocomplete = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.button_blue,
            width: styles.autocomplete.width,
            height: styles.autocomplete.height,
            text: "autosolve",
            onClicked: this.game.autocomplete.bind(this.game)
        });
        node.addChild(autocomplete);
        this.autocomplete = new HidingNode(autocomplete, cleverapps.UI.VERTICAL);
        cleverapps.focusManager.registerControl("autocomplete", this.autocomplete);

        node.calcRect = function () {
            return cc.rect(0, 0, this.autocomplete.x + this.autocomplete.width, node.height);
        }.bind(this);
        this.updateSize();

        this.game.updateButtons = this.createListener(function () {
            this.autocomplete.hide();

            if (this.game.outcome === GameBase.OUTCOME_UNKNOWN && this.game.autocompleteReady) {
                this.autocomplete.show();
            }
        }.bind(this));
    },

    setupChildren: function () {
        if (!this.isAllLoaded) {
            return;
        }
        this._super();

        var styles = cleverapps.styles.KlondikeScene;

        this.buttons.setContentSize(this.width, styles.autocomplete.height);
        this.buttons.setAnchorPoint(0.5, 0);
        this.buttons.setPositionRound(this.width / 2, 0);

        this.undoBlock.setPositionRound(styles.undo);
        this.hintBlock.setPositionRound(styles.hint);
        this.autocomplete.setPositionRound(styles.autocomplete.pos);
    }
});

KlondikeScene.GetAvailableHeight = function () {
    var height = cleverapps.resolution.getSceneSize().height;
    var table = cleverapps.scenes.getRunningScene().cardTableView;
    if (table) {
        height = (table.y + table.height * (1 - table.anchorY)) / table.scale;
    }
    return height;
};

GameScene = KlondikeScene;

cleverapps.styles.KlondikeScene = {
    autocomplete: {
        width: 420,
        height: 150,

        pos: [{
            x: { align: "center", dx: 440 },
            y: { align: "bottom", dy: 15 }
        }, {
            x: { align: "center", dx: 440 },
            y: { align: "bottom", dy: 15 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 15 }
        }]
    },

    undo: [{
        x: { align: "center", dx: -110 },
        y: { align: "bottom", dy: 15 }
    }, {
        x: { align: "center", dx: -110 },
        y: { align: "bottom", dy: 15 }
    }, {
        x: { align: "right", dx: -250 },
        y: { align: "bottom", dy: 15 }
    }],

    hint: [{
        x: { align: "center", dx: 110 },
        y: { align: "bottom", dy: 15 }
    }, {
        x: { align: "center", dx: 110 },
        y: { align: "bottom", dy: 15 }
    }, {
        x: { align: "right", dx: -30 },
        y: { align: "bottom", dy: 15 }
    }]
};