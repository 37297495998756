/**
 * Created by spepa on 03.02.2023
 */

var Klondike = function (level, options) {
    this.updateButtons = function () {};

    this.autocompleteReady = false;

    this.columns = [];
    for (var i = 0; i < 4; i++) {
        this.columns.push(new CardColumn(CardColumnTypes.OPEN));
    }
    for (var j = 0; j < 7; j++) {
        this.columns.push(new CardColumn(CardColumnTypes.TABLE));
    }
    this.columns.push(new CardColumn(CardColumnTypes.HAND));

    GameBase.call(this, level, options);

    this.began = this.moves > 0;
    this.generator = new TileGenerator();
    this.log = new MovesLog(this, this.savedGame.log);

    this.setColumns(this.savedGame);

    this.counter.registerStage(1, this.flip.bind(this));
    this.counter.registerStage(10, this.checkWin.bind(this));
    this.counter.registerStage(20, this.afterPlayer.bind(this));
    this.counter.registerStage(30, this.checkAutocomplete.bind(this));

    this.hand = new Hand(this, this.savedGame.hand || { num: Klondike.HAND_CARDS });

    this.onChangeMovesListener = function () {};
};

var Game = Klondike;
Klondike.prototype = Object.create(GameBase.prototype);
Klondike.constructor = Klondike;

Klondike.prototype.columnsByType = function (type) {
    return this.columns.filter(function (column) {
        return column.type === type;
    });
};

Klondike.prototype.getMode = function () {
    return GameBase.MODE_HIGHSCORE;
};

Klondike.prototype.setColumns = function (save) {
    Game.currentGame.generator.reset();

    if (save.columns) {
        save.columns.forEach(function (colCards, ind) {
            var cards = colCards.map(function (item) {
                return TileFactory.Create(item);
            });

            this.columns[ind].addCards(cards);
        }.bind(this));
    } else {
        [1, 2, 3, 4, 5, 6, 7].forEach(function (amount, colInd) {
            var cards = [];
            for (var i = 0; i < amount; i++) {
                cards.push(TileFactory.Create({}));
            }
            this.columnsByType(CardColumnTypes.TABLE)[colInd].addCards(cards);
        }.bind(this));
    }
};

Klondike.prototype.countOfCardsToPlay = function () {
    var open = this.columnsByType(CardColumnTypes.OPEN);
    return this.listCards().filter(function (card) {
        return !open.includes(card.owner);
    }).length;
};

Klondike.prototype.levelWithTutorial = function () {
    return false;
};

Klondike.prototype.checkWin = function () {
    if (this.countOfCardsToPlay() === 0) {
        this.win();
    }
};

Klondike.prototype.getInfo = function () {
    var info = GameBase.prototype.getInfo.call(this);

    if (!this.stopped) {
        info.log = this.log.getInfo();
        info.hand = this.hand.getInfo();
        info.score = this.score.getPoints();
        info.moves = this.moves;
        info.seconds = this.timer.getInGameTime();

        info.columns = this.columns.map(function (column) {
            return column.cards.map(function (card) {
                var info = card.toJSON();
                info.opened = card.isOpen();
                return info;
            });
        });
    }
    return info;
};

Klondike.prototype.stop = function () {
    this.generator.clear();
    this.log.clear();
    this.hand.clear();
    this.columns.forEach(function (column) {
        column.clear();
    });
    GameBase.prototype.stop.apply(this, arguments);
};

Klondike.prototype.afterPlayer = function () {
    if (this.move && this.outcome === GameBase.OUTCOME_UNKNOWN) {
        cleverapps.userStatus.reportUserAction();
        this.move = false;
    }
};

Klondike.prototype.flip = function (f) {
    this.columns.forEach(function (column) {
        column.flipNormalize();
    });

    f && f();
};

Klondike.prototype.onMoveDrag = function (cards) {
    var target;
    this.columns.forEach(function (column) {
        if (!target && column.isHovered(cards[0]) && column.canAccept(cards)) {
            target = column;
            column.trigger("highlight", cards);
        } else {
            column.trigger("unhighlight");
        }
    });
};

Klondike.prototype.incMoves = function () {
    if (!this.autocompleteReady) {
        this.moves++;
        this.onChangeMovesListener();
    }
};

Klondike.prototype.shiftCard = function () {
    var cards = [this.hand.stack.pop()];
    this.hand.trigger("shift");

    this.columnsByType(CardColumnTypes.HAND)[0].addCards(cards);

    cleverapps.audio.playSound(bundles.game.urls.shift_card_sfx, { throttle: 0 });
    this.move = Card.MOVES.SHIFT;
    this.log.push(MovesLog.MOVE_SHIFT_CARD, { score: this.score.getPoints() });
    this.incMoves();
    this.counter.trigger();
};

Klondike.prototype.reloadHand = function () {
    var handColumn = this.columnsByType(CardColumnTypes.HAND)[0];
    if (handColumn.isEmpty() || handColumn.inAnimation()) {
        return;
    }

    var cards = handColumn.cards.slice();
    handColumn.removeCards(cards);
    cards.reverse().forEach(function (card) {
        card.flip();
        Game.currentGame.hand.push(card);
    });

    cleverapps.audio.playSound(bundles.game.urls.reload_hand_sfx);
    this.move = Card.MOVES.SHIFT;
    this.log.push(MovesLog.MOVE_SHIFT_CARD, { score: this.score.getPoints() });
    this.score.addPoints(-100);
    this.incMoves();
    this.counter.trigger();
};

Klondike.prototype.tryMove = function (cards, fromDrag) {
    var newOwner = this.columns.filter(function (column) {
        return (!fromDrag || column.isHovered(cards[0])) && column.canAccept(cards, fromDrag);
    })[0];

    if (!newOwner) {
        return false;
    }

    var topCard = cards[0];
    this.logMove(topCard.owner, newOwner, cards);
    this.score.addPoints(topCard.owner.moveCost(newOwner, cards));
    this.incMoves();

    topCard.owner.removeCards(cards);
    newOwner.addCards(cards);
    var moveTime = topCard.calcMoveTime();
    newOwner.trigger("glee", moveTime);

    this.playCardSfx(newOwner);
    this.move = Card.MOVES.PLAY;
    this.counter.trigger();

    return true;
};

Klondike.prototype.playCardSfx = function (to) {
    var ind = this.columnsByType(CardColumnTypes.OPEN).indexOf(to);
    if (ind !== -1) {
        cleverapps.audio.playSound(bundles.game.urls["play_card_sfx" + ind], { throttle: 0 });
    } else {
        cleverapps.audio.playSound(bundles.game.urls.move_column_sfx);
    }
};

Klondike.prototype.checkAutocomplete = function () {
    this.autocompleteReady = this.listCards().filter(function (card) {
        return card.owner.type !== CardColumnTypes.OPEN && (!card.isOpen() || !card.owner.isMovable(card, true));
    }).length === 0;

    this.updateButtons();
};

Klondike.prototype.autocomplete = function () {
    if (this.counter.isActive()) {
        return;
    }

    cleverapps.focusManager.display({
        focus: "Autocomplete",
        actions: this.listCards().filter(function (card) {
            return card.owner.type !== CardColumnTypes.OPEN;
        }).map(function () {
            return function (f) {
                var hint = this.selectHint();
                this.score.addPoints(hint.card.owner.moveCost(hint.to, [hint.card]));

                hint.card.owner.removeCards([hint.card], true);
                hint.to.addCards([hint.card], true);
                hint.to.trigger("quickAccept");
                cleverapps.audio.playSound(bundles.game.urls.move_column_sfx, { throttle: 0 });

                this.counter.setTimeout(f, Card.AUTO_TIMEOUT * 1000);
            }.bind(this);
        }.bind(this))
    });
};

Klondike.prototype.logMove = function (oldOwner, newOwner, cards) {
    var params = {
        from: this.columns.indexOf(oldOwner),
        to: this.columns.indexOf(newOwner),
        cardsCount: cards.length,
        score: this.score.getPoints()
    };

    for (var i = 0; i < oldOwner.cards.length; i++) {
        if (oldOwner.cards[i].isOpen()) {
            params.firstOpenIndex = i;
            break;
        }
    }

    this.log.push(MovesLog.MOVE_PLAY_CARD, params);
};

Klondike.prototype.listCards = function () {
    var cards = this.hand.stack;
    this.columns.forEach(function (column) {
        cards = cards.concat(column.cards);
    });
    return cards;
};

Klondike.prototype.selectHint = function () {
    var hint = undefined;
    var handCol = this.columnsByType(CardColumnTypes.HAND)[0];
    var maxCost = 0;

    this.listCards().forEach(function (card) {
        this.columns.forEach(function (to) {
            var from = card.owner;
            var multiplier = 1;
            if (from.isMovable) {
                if (from.isMovable(card)) {
                    multiplier = 10;
                } else if (card.owner !== handCol) {
                    multiplier = 0;
                }
            }
            var cost = (from.moveCost ? from.moveCost(to, card.getTail()) : 1) * multiplier;
            if (from !== to && cost > maxCost && to.canAccept(card.getTail())) {
                maxCost = cost;
                hint = { card: card, to: to, from: from };
            }
        });
    }, this);

    return hint;
};

Klondike.prototype.addHint = function (hint) {
    var card = hint.card;
    var handCol = this.columnsByType(CardColumnTypes.HAND)[0];

    cleverapps.audio.playSound(bundles.game.urls.hint_sfx);
    if (card.owner === this.hand || (card.owner === handCol && !card.owner.isMovable(card))) {
        this.hand.trigger("showHint");
    } else {
        var tail = card.getTail();
        new ActionPlayer([
            function (f) {
                hint.from.removeCards(tail, true);
                hint.to.addCards(tail);

                this.counter.setTimeout(f, (card.calcMoveTime() + 0.5) * 1000);
            }.bind(this),
            function (f) {
                hint.to.removeCards(tail);
                hint.from.addCards(tail);

                this.counter.setTimeout(f, (card.calcMoveTime() + 0.1) * 1000);
            }.bind(this)
        ]).play();
    }
};

Klondike.prototype.selectMagic = function () {
    var magic = undefined;
    var openColumns = this.columnsByType(CardColumnTypes.OPEN);

    this.listCards().forEach(function (card) {
        if (!card.isOpen()) {
            openColumns.forEach(function (to) {
                if (to.canAccept([card])) {
                    var ind = card.owner.cards.indexOf(card);
                    card.owner.cards.splice(ind, 1);
                    to.cards.push(card);

                    if (!magic || this.selectHint() !== undefined) {
                        magic = { card: card, to: to };
                    }

                    to.cards.pop();
                    card.owner.cards.splice(ind, 0, card);
                }
            }.bind(this));
        }
    }.bind(this));

    return magic;
};

Klondike.prototype.setMoves = function () {
    var magic = this.selectMagic();
    var from = magic.card.owner;
    this.log.clear();

    cleverapps.focusManager.distract({
        focus: "showMagic",
        actions: [
            function (f) {
                cleverapps.timeouts.setTimeout(function () {
                    cleverapps.audio.playSound(bundles.game.urls.perform_magic_sfx);
                    from.removeCards([magic.card], true);
                    magic.to.addCards([magic.card], true);

                    magic.card.flip();
                    magic.card.trigger("playMagic");
                    f();
                }, 400);
            },

            function (f) {
                cleverapps.timeouts.setTimeout(function () {
                    this.playCardSfx(magic.to);
                    magic.to.trigger("quickAccept", Card.PLAY_TIMEOUT);
                    f();
                }.bind(this), 900);
            }.bind(this),

            function (f) {
                cleverapps.timeouts.setTimeout(function () {
                    from.trigger("updateCards");
                    f();
                }, 100);
            },

            function (f) {
                cleverapps.timeouts.setTimeout(f, 300);
            }
        ]
    });
};

Klondike.prototype.showScreen = function (f, silent) {
    if (silent) {
        this.score.show(true);
        this.columns.forEach(function (column) {
            column.trigger("addCards", f, true);
        });
        return;
    }

    var columns = this.columns.filter(function (column) {
        return !column.isEmpty();
    });

    cleverapps.focusManager.compound(f, [
        function (f) {
            setTimeout(f, 200);
        },

        function (f) {
            cleverapps.audio.playSound(bundles.game.urls.showup_dealing_sfx);
            var waiter = cleverapps.wait(columns.length, f);

            new ActionPlayer(
                columns.map(function (column) {
                    return function (f) {
                        column.trigger("addCards", waiter);
                        setTimeout(f, 50);
                    };
                })
            ).play();
        },

        function (f) {
            cleverapps.audio.playSound(bundles.game.urls.showup_open_sfx);
            this.score.show();
            this.flip(f);
        }.bind(this)
    ]);
};

Klondike.prototype.getPercentOfCompletion = function () {
    return 1 - this.countOfCardsToPlay() / Klondike.TOTAL_CARDS;
};

Klondike.TOTAL_CARDS = 52;
Klondike.TABLE_CARDS = 28;
Klondike.HAND_CARDS = Klondike.TOTAL_CARDS - Klondike.TABLE_CARDS;
